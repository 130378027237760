<template>
  <div ref="editorDiv">
    <ReportCreator ref="reportCreator"/>
    <b-modal ref="warningDialog" title="Report Editor Error"
      header-bg-variant="warning" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" @ok="handleWarningOK" ok-only >
      <span>{{warningMsg}}</span>
    </b-modal>
    <NormalCreator ref="normalCreator"/>
    <b-modal ref="warningDialog" title="Normal Editor Error"
      header-bg-variant="warning" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" @ok="handleWarningOK" ok-only >
      <span>{{warningMsg}}</span>
    </b-modal>
    <b-modal ref="speechToTextOptInDialog" title="Speech Recognition Opt In"
      header-bg-variant="primary" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" @ok="handleSpeechToTextOptIn" ok-title="Agree">
      <p>Before using speech recognition, you must agree to our terms and conditions of using speech recognition.</p><p>Contact Saince support for more information.</p>
    </b-modal>
    <a ref="altReportLauncher" target="_blank"></a>
    <b-badge class="w-100 overflow-hidden" variant="info" show size="sm">
      <b-icon v-if="entry.stat" scale="1.0" icon="patch-exclamation-fill" class="text-danger mr-1"></b-icon>
      <b-icon v-if="entry.read" scale="1.0" icon="check-circle-fill"></b-icon>
      &nbsp;
      {{ title }}
    </b-badge>
    <div>
      <b-badge class="mt-1 d-none d-sm-block" :variant="statusVariant" show size="sm">{{statusBadge}}</b-badge>
    </div>
    <b-navbar type="dark" variant="dark">
      <b-navbar-nav>
        <b-dropdown class="ml-1" size="sm" title="Reports for Patient" :variant="(studyList.length>1)?'info':'secondary'">
          <template #button-content>
            <b-icon icon="journals"></b-icon>
          </template>
          <b-dropdown-item v-for="s in studyList" :key="s.study_uid" :disabled="s.report_status=='---'" @click="secondaryStudy(s.study_uid)">
            <b-icon v-if="s.study_uid == entry.study_uid" icon="toggle-on" variant="info"/>
            <b-icon v-else icon="toggle-off" variant="secondary"/>
            {{(s.study_date_time == null) ? '---' : new Date(s.study_date_time).toLocaleString(locale)}}
            [{{s.modality.trim()}}]
            {{(s.report_status=='---')?'No Report':s.report_status}}
          </b-dropdown-item>
        </b-dropdown>
      </b-navbar-nav>
      <b-navbar-nav v-if="reportBuffer != ''" class="ml-1 align-middle text-light">
        <b-button-group v-if="(this.speechToTextApiKey!='')" size="sm">
          <b-button @click="startSpeechRecognition" title="Start Recording" :variant="(recordingState == 'RECORDING') ? 'success' : 'secondary'" :disabled="recordingState != 'STOPPED'">
            <span v-if="recordingState!='RECORDING'" class="material-icons">&#xe029;</span>
            <b-spinner v-if="recordingState=='RECORDING'" class="m-1" small type="grow"></b-spinner>
          </b-button>
          <b-button class="ml-1" @click="completeSpeechRecognition" title="End Recording and Transcribe" :variant="(recordingState == 'RECORDING') || (recordingState == 'TRANSCRIBING')? 'primary' : 'secondary'" :disabled="recordingState != 'RECORDING'">
            <span v-if="recordingState!='TRANSCRIBING'" class="material-icons">&#xf8ec;</span>
            <b-spinner v-if="recordingState=='TRANSCRIBING'" class="m-1" small type="grow"></b-spinner>
          </b-button>
          <b-button class="ml-1" @click="cancelSpeechRecognition" title="Cancel Recording" :variant="(recordingState == 'RECORDING') ? 'danger' : 'secondary'" :disabled="!isRecording">
            <b-icon icon="x-octagon"></b-icon>
          </b-button>
        </b-button-group>
        <div id="speechToTextTimer" class="ml-1 timerDiv text-dark">00:00</div>
      </b-navbar-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav v-if="reportBuffer != ''" class="ml-auto">
        <b-spinner v-if="saveInProgress" label="Saving..." variant="info"/>
        <span v-if="!saveInProgress && dirty" :title="lastSaved" class="text-warning"><b-icon icon="journal-medical"/></span>
        <span v-if="!saveInProgress && !dirty" :title="lastSaved" class="text-success"><b-icon icon="journal-medical"/></span>
        <b-nav-text class="fixedHeight">&nbsp;</b-nav-text>
        <b-dropdown class="ml-1" size="sm" title="Insert Key Image" right variant="secondary" :disabled="saveInProgress || insertInProgress">
          <template #button-content>
            <b-icon icon="key"></b-icon>
          </template>
          <b-dropdown-item v-if="keyObjectsList.length==0" >
            <b-icon icon="info-circle"/>
            No Key Images
          </b-dropdown-item>
          <b-dropdown-item v-for="k in keyObjectsList" :key="k.id" @click="handleInsertKeyImage(k.id)">
            <b-icon icon="key-fill"/>
            S:{{k.series_num}} I:{{k.instance_num}} <span v-if="k.num_frames>1">F:{{k.frame_num}} </span>[{{k.series_desc}}]
          </b-dropdown-item>
        </b-dropdown>
        <b-button-group size="sm">
          <b-button class="ml-2" v-if="canDownload" @click="handleDownload('pdf')" variant="secondary" title="Download Report (PDF)" :disabled="isRecording">
            <b-icon icon="file-ppt"></b-icon>
          </b-button>
          <b-button v-if="canDownload" @click="handleDownload('docx')" variant="secondary" title="Download Report (DOCX)" :disabled="isRecording">
            <b-icon icon="file-word"></b-icon>
          </b-button>
          <b-button class="ml-2" v-if="canChangeTemplate" @click="handleChangeTemplate()" variant="warning" title="Change Template" :disabled="isRecording">
            <b-icon icon="journal-code"></b-icon>
          </b-button>
          <b-button class="ml-2" v-if="canChangeNormal" @click="handleChangeNormal()" variant="warning" title="Select Normal" :disabled="isRecording">
            <b-icon icon="journal-code"></b-icon>
          </b-button>
          <b-button class="ml-2" @click="handleSave(reportStatusForSave)" :title="saveTitle" :disabled="!canSaveReports || isRecording || saveInProgress || insertInProgress">
            <b-icon icon="journal-check"></b-icon>
          </b-button>
          <b-button variant="primary" class="ml-1" @click="handleSave(reportStatusForPreliminary)" :title="savePrelimTitle" :disabled="!canSignPrelim || isRecording || saveInProgress || insertInProgress">
            <b-icon icon="journal-bookmark"></b-icon>
          </b-button>
          <b-button variant="success" class="ml-1" @click="handleSave(reportStatusForFinal)" :title="saveFinalTitle" :disabled="!canSignFinal || isRecording || saveInProgress || insertInProgress">
            <b-icon icon="journal-bookmark-fill"></b-icon>
          </b-button>
          <b-button class="ml-2" @click="handleClose" title="Close Report" :disabled="isRecording">
            <b-icon icon="journal-x"></b-icon>
          </b-button>
        </b-button-group>
      </b-navbar-nav>
    </b-navbar>
    <b-alert v-if="reportError" class="mt-2" variant="warning" show>
      Editable report not ready.
      <b-button class="ml-2" variant="primary" @click="show()">Retry</b-button>
    </b-alert>
    <b-alert v-if="!reportError && (reportBuffer == '')" class="mt-2" variant="info" show>
      <b-spinner class="ml-2" label="Loading..." variant="info"/> Loading report...
    </b-alert>
    <div id="doceditdiv" ref="doceditdiv" :width="editorWidth">
      <div>
        <!--<input type="file" ref="fileUpload" v-on:change="onFileUpload" accept=".doc,.docx," style="position:fixed; left:-100em"/>-->
        <ejs-toolbar v-on:clicked="toolbarButtonClick">
          <e-items> 
            <e-item prefixIcon="e-de-ctnr-bold e-icons" tooltipText="Bold" id="bold"></e-item>
            <e-item prefixIcon="e-de-ctnr-italic e-icons" tooltipText="Italic" id="italic"></e-item>
            <e-item prefixIcon="e-de-ctnr-underline e-icons" tooltipText="Underline" id="underline"></e-item>
            <e-item prefixIcon="e-de-ctnr-strikethrough e-icons" tooltipText="Strikethrough" id="strikethrough"></e-item>
            <e-item prefixIcon="e-de-ctnr-subscript e-icons" tooltipText="Subscript" id="subscript"></e-item>
            <e-item prefixIcon="e-de-ctnr-superscript e-icons" tooltipText="Superscript" id="superscript"></e-item>
            <e-item type="Seperator"></e-item>  
            <e-item type="Input" template="fontColorTemplate"></e-item>
            <e-item type="Seperator"></e-item>
            <e-item type="Input" template="fontFamilyTemplate"></e-item>
            <e-item type="Input" template="fontSizeTemplate"></e-item>
            <e-item type="Seperator"></e-item>  
            <e-item type="Seperator"></e-item> 
            <e-item prefixIcon='e-de-ctnr-alignleft e-icons' id='AlignLeft' tooltipText='Align Left'></e-item>
            <e-item prefixIcon='e-de-ctnr-aligncenter e-icons' id='AlignCenter' tooltipText='Align Center'></e-item>
            <e-item prefixIcon='e-de-ctnr-alignright e-icons' id='AlignRight' tooltipText='Align Right'></e-item>  
            <e-item prefixIcon='e-de-ctnr-justify e-icons' id='Justify' tooltipText='Justify'></e-item>
            <e-item prefixIcon='e-de-ctnr-increaseindent e-icons' id='IncreaseIndent' tooltipText='Increase Indent'></e-item>
            <e-item prefixIcon='e-de-ctnr-decreaseindent e-icons' id='DecreaseIndent' tooltipText='Decrease Indent'></e-item>
            <e-item type="Input" template="lineSpacingTemplate"></e-item>
            <e-item type="Input" template="bulletTemplate"></e-item>
            <e-item type="Input" template="numberingTemplate"></e-item>
            <e-item type='Separator'></e-item>
            <e-item prefixIcon='e-de-ctnr-clearall e-icons' id='ClearFormat' tooltipText='ClearFormatting'></e-item>
            <e-item type='Separator'></e-item>
            <e-item prefixIcon='e-de-e-paragraph-mark e-icons' id='ShowParagraphMark'
              tooltipText='Show the hidden characters like spaces, tab, paragraph marks, and breaks.(Ctrl + *)'></e-item>
            <!--<e-item prefixIcon ='e-de-ctnr-upload e-icons' id='importnewtemplates' tooltipText='Import New Templates from Local'></e-item>-->
            <e-item type="Seperator"></e-item>  
            <e-item type="Seperator"></e-item>
            <e-item prefixIcon ='e-de-ctnr-lock e-icons' id='showhidetoolbar' tooltipText='Show/Hide Toolbar'></e-item>  
          </e-items>
          <template v-slot:fontColorTemplate>
            <ejs-colorpicker value='#000000' :showButtons='true' v-bind:change='onFontColorChange'> </ejs-colorpicker>
          </template>
          <template v-slot:fontFamilyTemplate>
            <ejs-combobox :dataSource='fontStyle' :width='120' :index='2' :allowCustom='true'
              v-bind:change='onFontFamilyChange' :showClearButton='false'> </ejs-combobox>
          </template>
          <template v-slot:fontSizeTemplate>
            <ejs-combobox :dataSource='fontSize' :width='80' :index='2' :allowCustom='true'
              v-bind:change='onFontSizeChange' :showClearButton='false'> </ejs-combobox>
          </template>
          <template v-slot:lineSpacingTemplate>
            <ejs-combobox :dataSource='lineSpacing' :width='80' :index='2' :allowCustom='true'
              v-bind:change='onLineSpacingChange' :showClearButton='false'> </ejs-combobox>
          </template>
          <template v-slot:bulletTemplate>
            <ejs-dropdownbutton v-bind:select='bulletButtonClick' :items='bullet' :with="40" iconCss='e-de-ctnr-bullets e-icons' cssClass='e-caret-hide'></ejs-dropdownbutton>
          </template>
          <template v-slot:numberingTemplate>
            <ejs-dropdownbutton v-bind:select='numberingButtonClick' :items='numbering' :with="40" iconCss='e-de-ctnr-numbering e-icons' cssClass='e-caret-hide'></ejs-dropdownbutton>
          </template> 
        </ejs-toolbar>
      </div>
      <ejs-documenteditorcontainer ref="doceditcontainer" v-bind:selectionChange='onSelectionChange' :toolbarItems='items' 
        :height="editorHeight"
        :serviceUrl='serviceUrl'
        :documentEditorSettings="fontStyle"
        :enableLocalPaste='false'
        :enableToolbar="true"
        :enableSpellCheck='true'
        :enableSfdtExport='true'
        :enableWordExport='true'
        :enableTextExport='true'
        :showPropertiesPane='false'>
      </ejs-documenteditorcontainer>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import AudioRecorder from 'audio-recorder-polyfill'
import uuid from 'uuid'
import broadcast  from '../common/broadcast'
import dicomweb from '../common/dicomweb'
import permissions from '../common/permissions'
import speechRecognition from '../common/speechRecognition'
import webServices from '../common/webServices'
import workflow  from '../common/workflow'

// SyncFusion Document Editor
// https://ej2.syncfusion.com/vue/documentation/document-editor/getting-started/#run-the-documenteditor-application
//
import ReportCreator from './ReportCreator.vue'
import NormalCreator from './NormalCreator.vue'

import { DocumentEditorContainerComponent as EjsDocumenteditorcontainer, Toolbar } from '@syncfusion/ej2-vue-documenteditor';
import { ItemDirective as EItem, ItemsDirective as EItems, ToolbarComponent as EjsToolbar } from "@syncfusion/ej2-vue-navigations";
import { ColorPickerComponent as EjsColorpicker } from '@syncfusion/ej2-vue-inputs';
import { ComboBoxComponent as EjsCombobox } from "@syncfusion/ej2-vue-dropdowns";
import { DropDownButtonComponent as EjsDropdownbutton } from "@syncfusion/ej2-vue-splitbuttons";
import { ref } from 'vue';


// Context Menu
import { ContextMenu } from '@syncfusion/ej2-navigations';
import { Browser } from '@syncfusion/ej2-base';

export default {
  name: 'reportEditor',
  components: {
    ReportCreator,
    NormalCreator,
    "ejs-documenteditorcontainer": EjsDocumenteditorcontainer, EItem, EItems, EjsToolbar, EjsColorpicker, EjsCombobox, EjsDropdownbutton
  },
  data() {
    return {
      chunks: [],
      contextMenuObj: null,
      dirty: false,
      documentEditorSettings: {
        fontFamilies: this.$store.state.reportFonts
      },
      editorEnableToolbar: false,
      editorHeight: '200px',
      editorWidth: '100px',
      insertInProgress: false,
      keyObjectsList: [],
      lastSaveTs: null,
      mediaRecorder: null,
      mediaStream: null,
      phraseTracker: null,
      recordingStartTime: 0,
      recordingState: 'STOPPED',
      reportBuffer: '',
      reportError: false,
      rptI: -1,
      reportList: [],
      speechToTextApiKey: "",
      speechToTextModel: "default",
      studyList: [],
      saveInProgress: false,
      warningMsg: '',
      doceditcontainer: ref(null),
      fontStyle: this.$store.state.reportFonts,
      fontSize: ['8', '9', '10', '11', '12', '14', '16', '18', '20', '22', '24', '26', '28', '36', '48', '72', '96'],
      lineSpacing: ['Single', '1.15', '1.5', 'Double'],
      bullet: [
        {
          iconCss: 'e-de-ctnr-bullet-none e-icons e-de-ctnr-list', text: 'None', id : '1'
        },
        {
          iconCss: 'e-de-ctnr-bullet-dot e-icons e-de-ctnr-list', text: 'Dot', id : '2'
        },
        {
          iconCss: 'e-de-ctnr-bullet-circle e-icons e-de-ctnr-list', text: 'Circle', id : '3'
        },
        {
          iconCss: 'e-de-ctnr-bullet-square e-icons e-de-ctnr-list', text: 'Square', id : '4'
        },
        {
          iconCss: 'e-de-ctnr-bullet-flower e-icons e-de-ctnr-list', text: 'Flower', id : '5'
        },
        {
          iconCss: 'e-de-ctnr-bullet-arrow e-icons e-de-ctnr-list', text: 'Arrow', id : '6'
        },
        {
          iconCss: 'e-de-ctnr-bullet-tick e-icons e-de-ctnr-list', text: 'Tick', id : '7'
        },
      ],

    numbering: [
      {
        text: 'None', id : '1'
      },
      {
        text: '1.___', id : '2'
      },
      {
        text: 'a.___', id : '3'
      },
      {
      text: 'A.___', id : '4'
      },
      {
        text: 'i.___', id : '5'
      },
      {
        text: 'I.___', id : '6'
      }
    ],
   
    items: ['Undo', 'Redo', 'Separator', 'Image', 'Table', 'Hyperlink', 'Bookmark', 'TableOfContents', 'Separator', 'Header', 'Footer', 'PageSetup', 'PageNumber', 'Break', 'InsertFootnote', 'InsertEndnote', 'Separator', 'Find', 'Separator', 'Comments', 'TrackChanges', 'Separator', 'LocalClipboard', 'RestrictEditing', 'Separator', 'FormFields', 'UpdateFields','ContentControl'],

    };
  },
  props: {
    "closeOpensViewer": Boolean,
    "inReportWindow": Boolean
  },
  provide: {
    DocumentEditorContainer: [Toolbar]
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    entry() {
      const studyUid = this.$store.state.activeStudyUid
      const entry = this.$store.getters.worklistEntryForStudy(studyUid)
      if (entry != null) {
        return entry
      }
      else {
        return webServices.getEmptyWorklistEntry()
      }
    },
    locale() {
      return this.$store.state.locale
    },
    lastSaved() {
      let ls = (this.lastSaveTs == null) ? '---' : new Date(this.lastSaveTs).toLocaleString(this.locale)
      ls += (this.dirty) ? ' (Unsaved changes)' : ' (No unsaved changes)'
      return ls
    },
    activeComponent() {
      return this.$store.state.activeComponent
    },
    canChangeTemplate() {
      const draft = this.reportStatus.endsWith('DRAFT')
      return draft
    },
    canChangeNormal() {
      const draft = this.reportStatus.endsWith('DRAFT')
      return draft
    },
    canDownload() {
      return permissions.hasPermission(this.entry.group, permissions.CAN_DOWNLOAD_REPORT)
    },
    canSaveReports() {
      return (!this.saveInProgress && (this.reportBuffer!='') && permissions.hasPermission(this.entry.group, permissions.CAN_EDIT_REPORTS))
    },
    canSignPrelim() {
      const can_sign = permissions.hasPermission(this.entry.group, permissions.CAN_SIGN_FINAL) || permissions.hasPermission(this.entry.group, permissions.CAN_SIGN_PRELIM)
      const draft = this.reportStatus.endsWith('DRAFT') || this.reportStatus.endsWith('FINAL')
      return (!this.saveInProgress && (this.reportBuffer!='') && draft && can_sign)
    },
    canSignFinal() {
      return (!this.saveInProgress && (this.reportBuffer!='') && permissions.hasPermission(this.entry.group, permissions.CAN_SIGN_FINAL))
    },
    isRecording() {
      return (this.recordingState == 'INITIALIZING') || (this.recordingState == 'RECORDING') || (this.recordingState == 'TRANSCRIBING')
    },
    reportId() {
      return this.$store.state.reportId
    },
    reportStatus() {
      var status = 'DRAFT'
      if ((this.rptI != -1) && (this.reportList != null)) {
        status = this.reportList[this.rptI].status
      }
      return status
    },
    statusBadge() {
      var status = ''
      if ((this.rptI != -1) && (this.reportList != null)) {
        status = this.reportList[this.rptI].status
        status += ' (Reported by: '+this.reportList[this.rptI].userFullName+')'
      }
      return status
    },
    statusVariant() {
      var status = 'DRAFT'
      if ((this.rptI != -1) && (this.reportList != null)) {
        status = this.reportList[this.rptI].status
      }
      return webServices.reportStatusToVariant(status, this.entry.read, this.entry.stat)
    },
    reportStatusForAutoSave() {
      var status = 'DRAFT'
      if ((this.reportStatus == 'FINAL') || this.reportStatus.startsWith('AMENDED ')) {
        status = 'AMENDED DRAFT'
      }
      return status
    },
    reportStatusForSave() {
      var status = this.reportStatus
      if ((this.reportStatus == 'FINAL') || (this.reportStatus == 'AMENDED FINAL')) {
        status = 'AMENDED DRAFT'
      }
      return status
    },
    reportStatusForPreliminary() {
      var status = 'PRELIMINARY'
      if ((this.reportStatus == 'FINAL') || this.reportStatus.startsWith('AMENDED ')) {
        status = 'AMENDED PRELIMINARY'
      }
      return status
    },
    reportStatusForFinal() {
      var status = 'FINAL'
      if ((this.reportStatus == 'FINAL') || this.reportStatus.startsWith('AMENDED ')) {
        status = 'AMENDED FINAL'
      }
      return status
    },
    saveTitle() {
      return 'Save ('+this.reportStatusForSave.replace('_', ' ')+')'
    },
    savePrelimTitle() {
      return 'Save ('+this.reportStatusForPreliminary.replace('_', ' ')+')'
    },
    saveFinalTitle() {
      return 'Save ('+this.reportStatusForFinal.replace('_', ' ')+')'
    },
    title() {
      return webServices.getTitleForEntry(this.entry)
    },
    serviceUrl() {
      this.$log.debug('serviceUrl='+this.$store.state.docServicesUrl)
      return this.$store.state.docServicesUrl
    }
  },
  watch: {
    activeComponent(newVal/*, oldVal*/) {
      if ((newVal != 'ReportViewer') && (newVal != 'ReportEditor')) {
        // Release lock for this report
        //
      this.releaseLock(this.entry.study_uid)
      }
    },
  },
  mounted() {
    var toolbar = document.querySelector('.e-de-ctnr-toolbar');
    toolbar.style.display = "none";
  },
  methods: {
    toolbarButtonClick(arg) {
      switch (arg.item.id) {
        case 'bold':
          //Toggles the bold of selected content
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.toggleBold();
          break;
        case 'italic':
          //Toggles the Italic of selected content
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.toggleItalic();
          break;
        case 'underline':
          //Toggles the underline of selected content
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.toggleUnderline('Single');
          break;
        case 'strikethrough':
          //Toggles the strikethrough of selected content
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.toggleStrikethrough();
          break;
        case 'subscript':
          //Toggles the subscript of selected content
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.toggleSubscript();
          break;
        case 'superscript':
          //Toggles the superscript of selected content
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.toggleSuperscript();
          break;
        case 'AlignLeft':
          //Toggle the Left alignment for selected or current paragraph
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.toggleTextAlignment('Left');
          break;
        case 'AlignRight':
          //Toggle the Right alignment for selected or current paragraph
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.toggleTextAlignment('Right');
          break;
        case 'AlignCenter':
          //Toggle the Center alignment for selected or current paragraph
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.toggleTextAlignment('Center');
          break;
        case 'Justify':
          //Toggle the Justify alignment for selected or current paragraph
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.toggleTextAlignment('Justify');
          break;
        case 'IncreaseIndent':
          //Increase the left indent of selected or current paragraph
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.increaseIndent();
          break;
        case 'DecreaseIndent':
          //Decrease the left indent of selected or current paragraph
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.decreaseIndent();
          break;
        case 'ClearFormat':
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.clearFormatting();
          break;
        case 'ShowParagraphMark':
          //Show or hide the hidden characters like spaces, tab, paragraph marks, and breaks.
          this.$refs.doceditcontainer.ej2Instances.documentEditor.documentEditorSettings.showHiddenMarks = !this.$refs.doceditcontainer.ej2Instances.documentEditor.documentEditorSettings.showHiddenMarks;
          break;
        case 'showhidetoolbar':
          this.handleResize()
          var toolbar = document.querySelector('.e-de-ctnr-toolbar');
          if (toolbar.style.display === "none") {
            toolbar.style.display = "block";
          } else {
            toolbar.style.display = "none";
          }
          break;
      }
    },
    numberingButtonClick(args) {
      switch (args.item.id) {
        case '1':
          //To create bullet list
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.clearList();
          break;
        case '2':
          //To create bullet list
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.applyNumbering('%1.', 'Arabic');
          break;
        case '3':
          //To create bullet list
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.applyNumbering('%1.', 'LowLetter');
          break;
        case '4':
          //To create bullet list
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.applyNumbering('%1.', 'UpLetter');
          break;
        case '5':
          //To create bullet list
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.applyNumbering('%1.', 'LowRoman');
          break;
        case '6':
          //To create bullet list
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.applyNumbering('%1.', 'UpRoman');
          break;
      }
    },
    bulletButtonClick(args) {
      switch (args.item.id) {
        case '1':
          //To create bullet list
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.clearList();
          break;
        case '2':
          //To create bullet list
          //this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.applyBullet(String.fromCharCode(61623), 'Symbol');
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.applyBullet('●', 'Symbol');
          break;
        case '3':
          //To create bullet list
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.applyBullet(String.fromCharCode(61551) + String.fromCharCode(32), 'Symbol');
          break;
        case '4':
          //To create bullet list
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.applyBullet(String.fromCharCode(61607), 'Wingdings');
          break;
        case '5':
          //To create bullet list
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.applyBullet(String.fromCharCode(61558), 'Wingdings');
          break;
        case '6':
          //To create bullet list
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.applyBullet(String.fromCharCode(61656), 'Wingdings');
          break;
        case '7':
          //To create bullet list
          this.$refs.doceditcontainer.ej2Instances.documentEditor.editor.applyBullet(String.fromCharCode(61692), 'Wingdings');
          break;
      }
    },
    onFontFamilyChange(args) {
      this.$refs.doceditcontainer.ej2Instances.documentEditor.selection.characterFormat.fontFamily = args.value;
      this.$refs.doceditcontainer.ej2Instances.documentEditor.focusIn();
    },
    onFontSizeChange(args) {
      this.$refs.doceditcontainer.ej2Instances.documentEditor.selection.characterFormat.fontSize = args.value;
      this.$refs.doceditcontainer.ej2Instances.documentEditor.focusIn();
    },
    onFontColorChange(args) {
      this.$refs.doceditcontainer.ej2Instances.documentEditor.selection.characterFormat.fontColor = args.currentValue.hex;
      this.$refs.doceditcontainer.ej2Instances.documentEditor.focusIn();
    },
    onLineSpacingChange(args) {
      var value;
      switch (args.value) {
          case 'Single':
              value = 1;
              break;
          case '1.15':
              value = 1.15;
              break;
          case '1.5':
              value = 1.5;
              break;
          case 'Double':
              value = 2;
              break;
      }
      this.$refs.doceditcontainer.ej2Instances.documentEditor.selection.paragraphFormat.lineSpacing = value;
      this.$refs.doceditcontainer.ej2Instances.documentEditor.focusIn();
    },
    onSelectionChange() {
      var characterformat = this.$refs.doceditcontainer.ej2Instances.documentEditor.selection.characterFormat;
      var properties = [characterformat.bold, characterformat.italic, characterformat.underline, characterformat.strikeThrough];
      var toggleBtnId = ["bold", "italic", "underline", "strikethrough"];
      // this.$refs.fontFamilyTemplate.value = characterformat.fontFamily;
      for (var i = 0; i < properties.length; i++) {
        let toggleBtn = document.getElementById(toggleBtnId[i]);
        if ((typeof (properties[i]) == 'boolean' && properties[i] == true) || (typeof (properties[i]) == 'string' && properties[i] !== 'None'))
          toggleBtn.classList.add("e-btn-toggle");
        else {
          if (toggleBtn.classList.contains("e-btn-toggle"))
            toggleBtn.classList.remove("e-btn-toggle");
        }
      }
      if (this.$refs.doceditcontainer.ej2Instances.documentEditor.selection) {
        var paragraphFormat = this.$refs.doceditcontainer.ej2Instances.documentEditor.selection.paragraphFormat;
        toggleBtnId = ['AlignLeft', 'AlignCenter', 'AlignRight', 'Justify', 'ShowParagraphMark'];
        for (var j = 0; j < toggleBtnId.length; j++) {
          let toggleBtn = document.getElementById(toggleBtnId[j]);
          //Remove toggle state.
          toggleBtn.classList.remove('e-btn-toggle');
        }
        //Add toggle state based on selection paragraph format.
        if (paragraphFormat.textAlignment === 'Left') {
          document.getElementById('AlignLeft').classList.add('e-btn-toggle');
        } else if (paragraphFormat.textAlignment === 'Right') {
          document.getElementById('AlignRight').classList.add('e-btn-toggle');
        } else if (paragraphFormat.textAlignment === 'Center') {
          document.getElementById('AlignCenter').classList.add('e-btn-toggle');
        } else {
          document.getElementById('Justify').classList.add('e-btn-toggle');
        }
        if (this.$refs.doceditcontainer.ej2Instances.documentEditor.documentEditorSettings.showHiddenMarks) {
          document.getElementById('ShowParagraphMark').classList.add('e-btn-toggle');
        }
      }
    },
    async show() {
      if (this.contextMenuObj == null) {
        // +TODO+ Add icons with iconCss properties for each menu item.
        // +TODO+ Handle Paste Special correctly (CTRL-v/CMD-v works with formatting, images).
        //
        let menuItems = []
        menuItems.push({ text: 'Copy' })
        menuItems.push({ text: 'Cut' })
        if (navigator.clipboard) {
          menuItems.push({ text: 'Paste Text' })
          //menuItems.push({ text: 'Paste Special' })
        }
        menuItems.push({ separator: true })
        menuItems.push({ text: 'Hyperlink…' })
        menuItems.push({ text: 'Font…' })
        menuItems.push({ text: 'Paragraph…' })

        //ContextMenu model definition
        let menuOptions = {
            target: '#doceditdiv',
            items: menuItems,
        };

        this.contextMenuObj = new ContextMenu(menuOptions, '#contextmenu');
        this.contextMenuObj.animationSettings.effect = (Browser.isDevice) ? 'ZoomIn' : 'SlideDown';
        this.contextMenuObj.addEventListener('select', this.handleContextMenu)
      }

      this.documentEditorSettings = {
        fontFamilies: this.$store.state.reportFonts
      }

      while(!this.$refs.doceditcontainer || !this.$refs.doceditcontainer.ej2Instances || !this.$refs.doceditcontainer.ej2Instances.documentEditor) {
        this.$log.debug("Waiting for documentEditor to be instantiated.")
        await webServices.sleep(250)
      }
      var obj = this.$refs.doceditcontainer.ej2Instances.documentEditor

      try {
        obj.removeEventListener("contentChange", this.handleContentChange)
        obj.removeEventListener("keyDown", this.handleKeyDown)
        obj.removeEventListener("selectionChange", this.handleSelectionChange)
      }
      catch(err) {
        this.$log.warn(`Error removing event listeners from document editor: ${err.message}`)
      }
      obj.openBlank()
      obj.enableContextMenu = true // replaced default with one above to handle paste from system clipboard

      // Configure spell checker: https://ej2.syncfusion.com/javascript/documentation/document-editor/spell-check/
      // Server-side: https://hub.docker.com/r/syncfusion/word-processor-server
      //
      //obj.spellChecker.languageID = 1033; // LCID of "en-US"
      //obj.spellChecker.removeUnderline = false;
      //obj.spellChecker.allowSpellCheckAndSuggestion = true;
      //obj.spellChecker.enableOptimizedSpellCheck = true;

      this.$log.debug(`ReportEditor show reportId=${this.reportId} studyUID=${this.entry.study_uid}`)
      this.dirty = false
      this.recordingState = 'STOPPED'
      this.mediaRecorder = null
      this.mediaStream = null
      this.reportBuffer = ''
      this.reportList = []
      this.keyObjectsList = []
      this.studyList = []
      this.reportError = false
      this.saveInProgress = false
      this.insertInProgress = false
      this.editorEnableToolbar = false
      this.phraseTracker = null
      if ((this.reportId != '') && (this.entry.study_uid != '')) {
        this.rptI = -1
        this.reportList = []

        let format = 'sfdt'
        webServices.readReport(this.entry.study_uid, this.reportId, this.entry.group, format)
        .then(response => {
          if (response != null) { 
            this.$log.debug("readReport response for reportId=["+this.reportId+"]")
            try {
              var dataView = new DataView(response);
              var decoder = new TextDecoder('utf-8');
              this.reportBuffer = decoder.decode(dataView)
              this.$log.debug("readReport reportBuffer created for reportId=["+this.reportId+"]")
              this.lastSaveTs = Date.now()
              obj.open(this.reportBuffer)
              this.$log.debug("readReport opened report in editor reportId=["+this.reportId+"]")
              obj.documentName = 'report_'+this.reportId
              this.$log.debug("Read report ["+obj.documentName+"]")
            }
            catch(objErr) {
              this.$log.warn(`Error loading loading document into editor: ${objErr.message}`)
              this.reportError = true
            }
          }
          else {
            this.$log.debug("Report null or empty.")
            this.reportError = true
          }
        })
        .catch(err => {
          this.$log.warn("Error fetching report, err: "+err.message)
          if (this.$store.state.activeComponent == 'ReportEditor') {
            let retryTimeout = 5000
            this.$log.warn("Error fetching report, will retry in "+retryTimeout/1000+" seconds, err=: "+err)
            var reportId = this.reportId
            setTimeout(() => {
              if (reportId == this.reportId) {
                this.show()
              }
            }, retryTimeout);
          }
          else {
            this.$log.debug("Skipping retry for report SFDT")
          }
        })
        .finally(() => {
          this.initAutoSave()
          this.handleResize()
          obj.addEventListener("contentChange", this.handleContentChange)
          obj.addEventListener("keyDown", this.handleKeyDown)
          obj.addEventListener("selectionChange", this.handleSelectionChange)

          this.$log.debug("Querying server for speechToTextOptIn")
          webServices.readUserSetting("speechToTextOptIn")
          .then(response => {
            if (response != null) {
              this.$log.debug(`Found speechToTextOptIn=${response}`)
              this.speechToTextOptIn = response
            }
          })
          .catch(err => {
            this.$log.error("Error fetching cached settings: "+err)
          })

          this.$log.debug("Querying server for GC Speech-to-Text API Key.")
          webServices.readSystemSetting("gcSpeechToTextApiKey")
          .then(response => {
            if ((response != null) && (response.length > 0)) {
              this.speechToTextApiKey = response
            }
          })
          .catch(err => {
            this.$log.error("Error fetching cached settings: "+err)
          })
          this.$log.debug("Querying server for GC Speech-to-Text Model.")
          webServices.readSystemSetting("gcSpeechToTextModel")
          .then(response => {
            if ((response != null) && (response.length > 0)) {
              this.speechToTextModel = response
            }
          })
          .catch(err => {
            this.$log.error("Error fetching cached settings: "+err)
          })

          webServices.readReportList(this.entry)
          .then(response => {
            if ((response != null) && (Object.keys(response).length > 0)) {
              this.$log.debug("Read report list.")
              if ((response['group'] == this.entry.group) && (response['study_uid'] == this.entry.study_uid)) {
                this.reportList = response.reportList
                for (var r = 0; r < this.reportList.length; r++) {
                  if (this.reportList[r].reportId == this.reportId) {
                    this.rptI = r
                    break
                  }
                }
              }
            }
          })
          .catch(err => {
            this.$log.error("Error fetching report list: "+err.message)
          })

          webServices.readSinglePatientWorklist(this.entry)
          .then(response => {
            // Make sure response includes current study.
            //
            for (var s = 0; s < response.length; s++) {
              if (response[s].study_uid == this.entry.study_uid) {
                response.sort((a, b) => a.study_date_time - b.study_date_time)
                this.studyList = response
                break
              }
            }
          })
          .catch(err => {
            this.$log.error(`Unable to retrieve study list for current patient in report editor: ${err.message}`)
          })

          webServices.readKeyObjects(this.entry.group, this.entry.study_uid)
          .then(response => {
            if (response.keyObjects && (response.keyObjects.length > 0) && (response.keyObjects[0].study_uid = this.entry.study_uid)) {
              this.keyObjectsList = response.keyObjects
            }
          })
          .catch(err => {
            this.$log.error(`Unable to retrieve key objects list for current study in report editor: ${err.message}`)
          })
        })
      }
    },
    displayToast(message, variant) {
      this.$bvToast.toast(message, {
        autoHideDelay: 5000,
        solid: true,
        title: 'INSPIRE PACS',
        variant: variant,
      })
    },
    handleChangeTemplate() {
      this.$store.commit('changeActiveComponent', 'ReportViewer')
      this.$refs.reportCreator.show(this.reportId)
    },
    handleChangeNormal() {
      this.$store.commit('changeActiveComponent', 'ReportViewer')
      this.$refs.normalCreator.show(this.reportId)
    },
    handleClose() {
      var answer = true
      if (this.dirty) {
        answer = window.confirm('Do you really want to leave with unsaved changes?')
      }
      if (answer) {
        this.$store.commit('changeReportWindowsEditing', {
          'editing': false,
          'windowUid': this.$store.state.uid
        })
        if (this.closeOpensViewer) {
          this.$store.commit('changeActiveComponent', 'ReportViewer')
        }
        else {
          this.$store.commit('changeActiveStudyUid', '')
          this.$store.commit('changeActiveComponent', '')
        }
      }
    },
    releaseLock(closeStudyUid) {
      if (closeStudyUid != '') {
        workflow.closeStudy(closeStudyUid, workflow.TARGET_REPORT_SIDEPANEL, this.$store.state.uid)
        .then(() => {
          this.$log.debug(`closeStudy successful for report studyUid=[${closeStudyUid}]`)
        })
        .catch(err => {
          this.displayToast(err.message, 'warning')
        })
      }
    },
    handleContentChange() {
      this.dirty = true
      this.$store.commit('changeReportWindowsEditing', {
        'editing': true,
        'windowUid': this.$store.state.uid
      })
    },
    convertHtmlToSfdt(html) {
      // Remove nasty Microsoft elements from clipboard HTML if copied from Word.
      //
      this.$log.debug(html)
      const htmlClean = html.replace(/<o:p><\/o:p>/g, '')
      const xdoc = document.implementation.createDocument('http://www.w3.org/1999/xhtml', 'html', null)
      const body = document.createElementNS('http://www.w3.org/1999/xhtml', 'body')
      body.innerHTML = htmlClean
      xdoc.documentElement.appendChild(body);
      const xdocAsHtml = xdoc.documentElement.outerHTML
      this.$log.debug(xdocAsHtml)

      this.$log.debug("converting html to sfdt using Syncfusion container")
      const formData = new FormData();
      formData.append('files', new Blob([xdocAsHtml], {type: "text/html"}), 'clipboard.html');
      
      var docServicesImportUrl = `${this.serviceUrl}/import`
      axios.post(
        docServicesImportUrl, 
        formData,
        {
            headers: {
                //'Authorization': 'Bearer '+store.state.keycloak.token,
                'Content-Type': 'multipart/form-data'
            }
        })
      .then(response => {
        this.$log.debug('received doc_services response')
        var documentEditor = this.$refs.doceditcontainer.ej2Instances.documentEditor
        documentEditor.editor.paste(JSON.stringify(response.data))
      })
      .catch(err => {
        this.$log.error("doc_services error: "+err.message)
        const toastMsg = "Unable to convert clipboard contents, try using keyboard paste (CNTL-v/CMD-V)."
        this.displayToast(toastMsg, 'warning')
      })
    },
    toDataUrl(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
      })
    },
    async handleInsertKeyImage(keyObjectId) {
      const kosForId = this.keyObjectsList.filter(ko => { return (ko.id ==keyObjectId); });
      if (kosForId.length == 1) {  
        this.phraseTracker = null
        this.insertInProgress = true
        try {
          const koEntry = kosForId[0]
          const quality = 90
          const response = await dicomweb.getPixelDataRendered(this.entry, koEntry.series_uid, koEntry.sop_instance_uid, koEntry.frame_num, quality)
          const pixelDataBlob = new Blob([response], {type: "image/jpeg"})
          const dataUrl = await this.toDataUrl(pixelDataBlob)
          var documentEditor = this.$refs.doceditcontainer.ej2Instances.documentEditor
          let altText = `S:${koEntry.series_num} I:${koEntry.instance_num} `
          altText += (koEntry.numFrames > 1) ? `F:${koEntry.frame_num} ` : ''
          altText += `[${koEntry.series_desc}]`
          this.$log.debug(`insertImage(dataUrl, ${koEntry.cols}, ${koEntry.rows}, ${altText})`)
          documentEditor.editor.insertImage(dataUrl, koEntry.cols, koEntry.rows, altText)
        }
        catch(err) {
          this.$log.error(`Unable to insert key image: ${err.message}`)
          const toastMsg = "Unable to insert key image."
          this.displayToast(toastMsg, 'warning')
        }
        finally {
          this.insertInProgress = false
        }
      }
      else {
        this.$log.warn(`Unable to find key object for ${keyObjectId}`)
      }
    },
    handlePasteSpecial() {
            navigator.clipboard.read()
      .then(clipboardItems => {
        this.$log.debug(clipboardItems)
        for (const clipboardItem of clipboardItems) {
          if (clipboardItem.types.includes('text/html')) {
            for (const type of clipboardItem.types) {
              clipboardItem.getType(type)
              .then(blob => {
                if (type == 'text/html') {
                  blob.text()
                  .then(blobAsText => {
                    this.convertHtmlToSfdt(blobAsText)
                  })
                  .catch(bErr => {
                    this.$log.error(`Error getting text from clipboard item blob: ${bErr.message}`)
                  })
                }
              })
              .catch(ciErr => {
                this.$log.debug(`Error getting blob from clipboard item: ${ciErr.message}`)
              })
            }
          }
          else if (clipboardItem.types.includes('text/plain')) {
            // No HTML available in clibboard item, fall back to plain text.
            //
            this.handlePasteText()
          }
        }
      })
      .catch(err => {
        this.$log.debug(`Error copying from clipboard: ${err.message}`)
      })
          },
    handlePasteText() {
      navigator.clipboard.readText()
      .then(text => {
        this.$log.debug(`Clipboard text=${text}`)
        var documentEditor = this.$refs.doceditcontainer.ej2Instances.documentEditor
        documentEditor.editor.insertText(text)
      })
      .catch(err => {
        this.$log.debug(`Error copying from clipboard: ${err.message}`)
      })
    },
    handleContextMenu(event) {
      //event.preventDefault()
      //event.stopPropagation()

      try {
        if (event.item) {
          const selection = event.item.properties.text
          this.$log.debug(`ContextMenu selection=${selection}`)
          var documentEditor = this.$refs.doceditcontainer.ej2Instances.documentEditor
          if (selection == 'Font…') {
            documentEditor.showDialog('Font')
          }
          else if (selection == 'Hyperlink…') {
            documentEditor.showDialog('Hyperlink')
          }
          else if (selection == 'Paragraph…') {
            documentEditor.showDialog('Paragraph')
          }
          else if (selection == 'Copy') {
            documentEditor.selection.copy()
          }
          else if (selection == 'Cut') {
            documentEditor.editor.cut()
          }
          else if (selection == 'Paste Text') {
            this.handlePasteText()
          }
          else if (selection == 'Paste Special') {
            this.handlePasteSpecial()
          }
        }
      }
      catch(err) {
        this.$log.error(`Unable to handle context menu request: ${err.message}`)
      }
    },
    handleKeyDown(event) {
      // event.event is KeyboardEvent (https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent)
      //
      try {
        let keyboardEvent = event.event
        if (keyboardEvent.altKey || keyboardEvent.ctrlKey || keyboardEvent.metaKey || (keyboardEvent.key == "Shift")) {
          this.$log.debug(`ReportEditor keyDown event, ignoring key=[${keyboardEvent.key}].`)
          return
        }
        let selection = event.source.selection
        this.$log.debug(`ReportEditor keyDown event, key=[${keyboardEvent.key}] start=[${selection.startPage}][${selection.startOffset}] end=[${selection.endPage}][${selection.endOffset}]`)
        
        let startOffset1 = selection.startOffset.substring(0, selection.startOffset.lastIndexOf(';'))
        let startOffset2 = selection.startOffset.split(';').pop()
        let endOffset1 = selection.endOffset.substring(0, selection.endOffset.lastIndexOf(';'))
        let endOffset2 = selection.endOffset.split(';').pop()
        if ((selection.startPage == selection.endPage) && (startOffset1 == endOffset1) && (startOffset2 == endOffset2)) {
          if (this.phraseTracker == null) {
            if ((keyboardEvent.key != " ") && (keyboardEvent.key.length == 1)) {
              // Handle checking if existing characters before current cursor location. I.e., user
              // moved cursor within an existing word.
              //
              let newPhrase = true
              let newSentence = false
              const currentStart = selection.startOffset.slice()
              selection.extendForward()
              if ((selection.text.length > 0)) {
                let code = selection.text.charCodeAt(0)
                this.$log.debug(`forward selection.text=[${selection.text}] selection.text[0]=${code}`)
                if ((code != 13) && (code != 32)) {
                  newPhrase = false
                }
              }
              let offset2 = parseInt(startOffset2, 10)
              if (offset2 > 0) {
                this.$log.debug("checking backward startOffset2")
                selection.select(currentStart, currentStart)
                selection.extendBackward()
                if ((selection.text.length > 0)) {
                  let code = selection.text.charCodeAt(0)
                  this.$log.debug(`backward selection.text=[${selection.text}] selection.text[0]=${code}`)
                  if (code != 32) {
                    newPhrase = false
                  }
                  else {
                    // A space preceeds phrase, now check if a completed sentence preceeds.
                    //
                    offset2 = parseInt(selection.endOffset.split(';').pop(), 10)
                    while (offset2 > 0) {
                      selection.extendBackward()
                      let code = selection.text.charCodeAt(0)
                      if (code == 46) {
                        newSentence = true
                        offset2 = 0
                      }
                      else if (code != 32) {
                        offset2 = 0
                      }
                      else {
                        offset2 = selection.endOffset.split(';').pop()
                      }
                    }
                  }
                }
              }
              else {
                newSentence = true
              }
              selection.select(currentStart, currentStart)
              if (newPhrase) {
                this.$log.debug(`ReportEditor start tracking`)
                this.phraseTracker = {
                  page: selection.startPage,
                  startOffset1: startOffset1,
                  startOffset2: startOffset2,
                  phrase: keyboardEvent.key,
                  newSentence: newSentence
                }
              }
              else {
                this.$log.debug(`ReportEditor start tracking skipped`)
              }
            }
          }
          else if ((selection.startPage == this.phraseTracker.page) && (startOffset1 == this.phraseTracker.startOffset1) &&
            (parseInt(startOffset2, 10) == (parseInt(this.phraseTracker.startOffset2, 10) + this.phraseTracker.phrase.length)))
          {
            switch(keyboardEvent.key) {
              case " ":
              case ".":
              case ",":
              case ":":
              case ";":
              case "!":
              case "Enter":
              {
                // Handle replacement if needed.
                //
                this.$log.debug(`ReportEditor end tracking, replace phrase=[${this.phraseTracker.phrase}]`)
                let phraseTracker = { ...this.phraseTracker } // copy before selectionChange event fired from select()
                this.$log.debug(phraseTracker)
                let start = phraseTracker.startOffset1 + ";" + phraseTracker.startOffset2
                let end = phraseTracker.startOffset1 + ";" + (parseInt(this.phraseTracker.startOffset2, 10) + this.phraseTracker.phrase.length).toString()
                if (phraseTracker.phrase in this.$store.state.reportPhraseLut) {
                  this.$log.debug(`ReportEditor replace start=[${start}] end=[${end}] with [${this.$store.state.reportPhraseLut[phraseTracker.phrase]}]`)
                  event.source.selection.select(start, end)
                  event.source.editor.insertText(this.$store.state.reportPhraseLut[phraseTracker.phrase])
                }
                else if (phraseTracker.newSentence && this.$store.state.reportSettings.capitalize_sentences) {
                  // Auto-correct enabled
                  let phrase = phraseTracker.phrase.charAt(0).toUpperCase() + phraseTracker.phrase.slice(1)
                  this.$log.debug(`ReportEditor replace start=[${start}] end=[${end}] with [${phrase}]`)
                  event.source.selection.select(start, end)
                  event.source.editor.insertText(phrase)
                }
                this.phraseTracker = null
                break
              }

              case "Backspace":
              {
                const tmpPhrase = this.phraseTracker.phrase.substring(0, this.phraseTracker.phrase.length - 1)
                if (tmpPhrase.length > 0) {
                  this.phraseTracker.phrase = tmpPhrase
                }
                else {
                  this.phraseTracker = null
                }
                break
              }
              
              default:
              {
                if (keyboardEvent.key.length == 1) {
                  this.phraseTracker.phrase += keyboardEvent.key
                  this.$log.debug(`ReportEditor keep tracking, phrase=[${this.phraseTracker.phrase}]`)
                }
                //else {
                // +TODO+ Just ignore things like End?
                //}
                break
              }
            }
          }
          else {
            this.$log.debug(`ReportEditor end tracking 1`)
            this.phraseTracker = null
          }
        }
      }
      catch(error) {
        this.$log.warn(`ReportEditor unable to handle keyDown event: ${error.message}`)
        this.phraseTracker = null
      }
    },
    handleSelectionChange(event) {
      let selection = event.source.selection
      this.$log.debug(`ReportEditor selectionChange event, start=[${selection.startPage}][${selection.startOffset}] end=[${selection.endPage}][${selection.endOffset}]`)

      // Is this due to the a keyDown event handled already?
      //
      if (this.phraseTracker != null) {
        let startOffset1 = selection.startOffset.substring(0, selection.startOffset.lastIndexOf(';'))
        let startOffset2 = selection.startOffset.split(';').pop()
        let endOffset1 = selection.endOffset.substring(0, selection.endOffset.lastIndexOf(';'))
        let endOffset2 = selection.endOffset.split(';').pop()
        if ((selection.startPage == selection.endPage) && (startOffset1 == endOffset1) && (startOffset2 == endOffset2) &&
            (selection.startPage == this.phraseTracker.page) && (startOffset1 == this.phraseTracker.startOffset1) &&
            (parseInt(startOffset2, 10) == (parseInt(this.phraseTracker.startOffset2, 10) + this.phraseTracker.phrase.length)))
          {
            this.$log.debug(`ReportEditor selectionChange event, ignoring keyDown pos`)
          }
          else {
            // User moved cursor or some other input changed selection.
            //
            this.phraseTracker = null
          }
      }
    },
    handleDownload(format) {
      this.displayToast("Report download started...", 'info')
      webServices.readReport(this.entry.study_uid, this.reportId, this.entry.group, format)
      .then(response => {
        var patientName = this.entry.patient_name_dcm.replaceAll('^', '_')
        var patientId = this.entry.patient_id
        var studyDate = this.entry.study_date_dcm
        const reportName = "report_"+patientName+"_"+patientId+"_"+studyDate+"."+format
        var mimeType = (format == 'docx') ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' : 'application/pdf'
        let reportBlob = new Blob([response], {type: mimeType})
        let reportUrl = URL.createObjectURL(reportBlob)
        this.$log.debug('Direct link to report: ' + reportUrl)
        this.$refs.altReportLauncher.href = reportUrl;
        this.$refs.altReportLauncher.setAttribute("download", reportName)
        this.$refs.altReportLauncher.click()
      })
      .catch(err => {
        this.$log.error(`Unable to download report: ${err.message}`)
        this.displayToast("Report download failed.", 'danger')
      })
    },
    initAutoSave() {
      let autoSaveInterval = 300 * 1000 // default 5 minutes
      if (this.$store.state.reportSettings && this.$store.state.reportSettings.auto_save) {
        if (Number.isInteger(this.$store.state.reportSettings.auto_save)) {
          autoSaveInterval = this.$store.state.reportSettings.auto_save * 60 * 1000
        }
      }
      this.$log.debug(`autoSaveInterval=${autoSaveInterval} ms`)
      setTimeout(() => {
        if (this.dirty) {
          this.handleSave(this.reportStatusForAutoSave, true)
          this.lastSaveTs = Date.now()
        }
        this.initAutoSave()
      }, autoSaveInterval);
    },
    handleSave(status, auto=false) {
      if (!this.saveInProgress || !this.insertInProgress) {
        // PUT report
        this.saveInProgress = true
        this.$log.debug(`handleSave(status=${status}, auto=${auto})`)
        var obj = this.$refs.doceditcontainer.ej2Instances.documentEditor
        // +TODO+ avoid round-trip request to convert sftp to docx
        obj.saveAsBlob('Docx')
        .then(docxBlob => {
          obj.saveAsBlob('Sfdt') 
          .then(sfdtBlob => {
            webServices.updateReport(sfdtBlob,
              docxBlob,
              this.entry,
              this.reportId,
              status,
              auto)
            .then(() => {
              this.dirty = false
              this.saveInProgress = false
              this.$store.commit('changeReportWindowsEditing', {
                'editing': false,
                'windowUid': this.$store.state.uid
              })
              
              // Change back to report viewer...
              //
              this.displayToast("Report saved successfully", 'info')
              
              if (!auto) {
                this.$store.commit('changeActiveComponent', 'ReportViewer')

                // Update worklist entry...
                //
                webServices.readWorklist() // handle if running in worklist window
                broadcast.postMessage(broadcast.REFRESH_WORKLIST_MSG, this.$store.state.uid) // handle if in report window
              }
              webServices.sleep(500);
              this.handleClose()
              this.handleClose()
            })
            .catch(err => {
              this.$log.error("Error updating report: "+err.message)
              this.warningMsg = 'Report not saved.'
              this.$refs.warningDialog.show()
            })
          })
          .catch(err => {
              this.$log.error("Error getting report as arraybuffer: "+err.message)
              this.warningMsg = 'Report not saved.'
              this.$refs.warningDialog.show()
          })
        })
        .catch(err => {
            this.$log.error("Error getting report as blob: "+err.message)
            this.warningMsg = 'Report not saved.'
            this.$refs.warningDialog.show()
        })
      }
      else {
        this.$log.warn("Request to save report ignored as save already in progress.")
      }
    },
    handleSpeechRecognitionError(msg) {
      this.mediaRecorder = null
      this.mediaStream = null
      this.recordingState = 'STOPPED'
      this.warningMsg = msg
      this.$refs.warningDialog.show()
    },
    cancelSpeechRecognition() {
      this.recordingState = 'CANCELED'
      try {
        if (this.mediaStream) {
          this.$log.debug("Stopping this.mediaStream tracks")
          this.mediaStream.getTracks().forEach(track => {
              track.stop();
          });
        }
        else if (this.mediaRecorder) {
          this.$log.debug("Stopping this.mediaRecorder.stream tracks")
          this.mediaRecorder.stop()
          this.mediaRecorder.stream.getTracks().forEach(track => {
              track.stop();
          });
        }
      }
      catch(err) {
        this.$log.warn(`cancelSpeechRecognition: ${err.message}`)
        this.recordingState = 'STOPPED'
      }
    },
    completeSpeechRecognition() {
      this.recordingState = 'TRANSCRIBING'
      try {
        if (this.mediaStream != null) {
          this.$log.debug("Stopping this.mediaStream tracks")
          this.mediaStream.getTracks().forEach(track => {
              track.stop();
          });
        }
        else if (this.mediaRecorder) {
          this.$log.debug("Stopping this.mediaRecorder.stream tracks")
          this.mediaRecorder.stop()
          this.mediaRecorder.stream.getTracks().forEach(track => {
              track.stop();
          });
        }
      }
      catch(err) {
        this.$log.warn(`completeSpeechRecognition: ${err.message}`)
        this.recordingState = 'STOPPED'
      }
    },
    async startSpeechRecognition() {
      // Check if opt in was toggled.
      //
      if (!this.speechToTextOptIn) {
        this.$refs.speechToTextOptInDialog.show()
        return
      }

      // Check if microphone permission was denied. Some browsers may not support this permission (e.g., Firefox).
      // result.state should be in ['granted', 'prompt', 'denied']
      //
      try {
        let result = await navigator.permissions.query({name: 'microphone'})
        this.$log.info(`Microphone permission=${result.state}`)
        if (result && (result.state == 'denied')) {
          this.handleSpeechRecognitionError('Unable to access microphone. Check browser permissions.')
          return
        }
      }
      catch(permissionsError) {
        this.$log.warn(`Unable to access microphone permission state: ${permissionsError.message}`)
      }

      this.recordingState = 'INITIALIZING'
      try {
        // Ref: https://www.geeksforgeeks.org/create-a-video-and-audio-recorder-with-javascript-mediarecorder-api/
        // Ref: https://github.com/ai/audio-recorder-polyfill 
        //
        navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false
        })
        .then(mediaStream => {
          // Using AudioRecorder for Safari or any browser on iOS/iPadOS (Safari's MediaRecorder
          // ondataavailable is buggy).
          //
          var isEdge = /Edg/i.test(navigator.userAgent);
          var isSafari = /Safari/.test(navigator.userAgent);
          var isiDevice = /ipad|iphone|ipod/i.test(navigator.userAgent.toLowerCase());
          this.$log.debug(`userAgent=${navigator.userAgent} isSafari=${isSafari} isiDevice=${isiDevice}`)
          if ((isSafari && !isEdge) || isiDevice) {
            this.$log.info("using AudioRecorder for recording.")
            this.mediaRecorder = new AudioRecorder(mediaStream)
          }
          else {
            this.$log.info("using MediaRecorder for recording.")
            this.mediaRecorder = new MediaRecorder(mediaStream)
            this.mediaStream = mediaStream
          }
    
          // Set up event listeners to handle recording transitions/errors.
          //
          let _this = this
          this.mediaRecorder.addEventListener('start', () => {
            _this.$log.debug("MediaRecorder onstart")
            _this.recordingStartTime = Date.now()
            _this.recordingState = 'RECORDING'
            _this.handleRecordingTime()
          });
          this.mediaRecorder.addEventListener('dataavailable', event => {
            _this.$log.debug(`mediaRecorder ondataavailable event.data.type=${event.data.type} event.data.size=${event.data.size}`)
            if (event.data.size > 0) {
              _this.chunks.push(event.data);
            }
          });
          this.mediaRecorder.addEventListener('stop', () => {
            _this.$log.debug(`mediaRecorder onstop mimeType=${_this.mediaRecorder.mimeType}`)
  
            // Verify user stopped to transcribe and did not cancel recording.
            //
            if (_this.recordingState == 'TRANSCRIBING') {
              const mimeType = _this.mediaRecorder.mimeType
              if (_this.chunks.length > 0) {
                const audioBlob = new Blob(_this.chunks, { type: mimeType })
                this.$log.debug(audioBlob)
                _this.transcribeRecording(audioBlob)
              }
              else {
                _this.handleSpeechRecognitionError('Recording failed.')
              }
            }
            else {
              _this.recordingState = 'STOPPED'
            }
            _this.chunks = [];
          });
          this.mediaRecorder.addEventListener('error', (event) => {
            _this.$log.error(`Error recording audio: ${event.error.message}`)
            _this.cancelSpeechRecognition()
          });

          this.mediaRecorder.start();
        })
        .catch(mediaStreamErr => {
          this.$log.warn(`startSpeechRecognition: ${mediaStreamErr.message}`)
          this.handleSpeechRecognitionError('Unable to start recording.')
        })
      }
      catch(err) {
        this.$log.warn(`startSpeechRecognition: ${err.message}`)
        this.handleSpeechRecognitionError('Unable to start recording.')
      }
    },
    handleRecordingTime() {
      let speechToTextTimer = document.getElementById('speechToTextTimer')
      if (this.recordingState == 'RECORDING') {
        if (speechToTextTimer.classList.contains('text-dark')) {
          speechToTextTimer.classList.remove('text-dark')
        }
        var ss = Math.round(( Date.now() - this.recordingStartTime ) / 1000.0)
        var mm = Math.floor(ss / 60)
        ss -= (mm * 60)
        speechToTextTimer.innerHTML = String(mm).padStart(2, '0') + ":" + String(ss).padStart(2, '0')
        setTimeout(() => {
          this.handleRecordingTime()
        }, 200);
      }
      else if (this.recordingState == 'TRANSCRIBING') {
        speechToTextTimer.innerHTML = '<span class="material-icons">&#xeb5a;</span>'
        if (speechToTextTimer.classList.contains('text-dark')) {
          speechToTextTimer.classList.remove('text-dark')
        }
        else {
          speechToTextTimer.classList.add('text-dark')
        }
        setTimeout(() => {
          this.handleRecordingTime()
        }, 500);
      }
      else {
        speechToTextTimer.classList.add('text-dark')
        speechToTextTimer.innerHTML = "00:00"
      }
    },
    transcribeRecording(audioBlob) {
      // Convert to WAV format compatible with Google Speech-to-Text API.
      //
      speechRecognition.audioBlobToWavBlob(audioBlob)
      .then(wavBlob => {
        speechRecognition.wavToText(this.speechToTextApiKey, this.speechToTextModel, wavBlob)
        .then(transcript => {
            var editor = this.$refs.doceditcontainer.ej2Instances.documentEditor.editor
            editor.insertText(transcript)
            this.recordingState = 'STOPPED'
        })
        .catch(transcribeError => {
            this.$log.error(`Failed transcribing audio: ${transcribeError.message}`)
            this.warningMsg = 'Unable to transcribe recording.'
            this.$refs.warningDialog.show()
            this.recordingState = 'STOPPED'
        })
      })
      .catch(convertError => {
        this.$log.error(`Failed to convert audio to WAV format: ${convertError.message}`);
        this.warningMsg = 'Unable to transcribe recording.'
        this.$refs.warningDialog.show()
        this.recordingState = 'STOPPED'
      });
    },
    handleResize(/*event*/) {
      setTimeout(() => {
        try {
          this.editorHeight = "" + (window.innerHeight - 160) + "px"
          this.editorWidth = "" + this.$refs.editorDiv.clientWidth + "px"
          var obj = this.$refs.doceditcontainer.ej2Instances.documentEditor
          obj.resize()
          this.editorEnableToolbar = false
        }
        catch(err) {
          // Most likely component was destroyed before timeout
          this.$log.warn(`handleResize: ${err.message}`)
        }
      }, 1000);
    },
    handleSpeechToTextOptIn() {
      this.speechToTextOptIn = true
      this.startSpeechRecognition()
      this.$log.debug("Updating cache for speechToTextOptIn")
      webServices.updateUserSetting("speechToTextOptIn", this.speechToTextOptIn)
      .then(response => {
        this.$log.debug(response.data)
      })
      .catch(err => {
        this.$log.error("Error updating speechToTextOptIn: "+err)
      })
    },
    handleWarningOK() {
      this.saveInProgress = false
    },
    secondaryStudy(studyUid) {
      if (studyUid == this.entry.study_uid) {
        return;
      }
      let secondaryEntry = null
      for (var s = 0; s < this.studyList.length; s++) {
        if (this.studyList[s].study_uid == studyUid) {
          secondaryEntry = this.studyList[s]
          break;
        }
      }
      if (secondaryEntry !== null) {
        this.$log.debug(`Requesting secondary report window for studyUid=${studyUid}`)
        if (this.inReportWindow || this.$store.state.inViewerWindow) {
          // Let primary window handle opening report window.
          //
          broadcast.postMessage(broadcast.OPEN_REPORT_WINDOW_MSG, {
            'entry': secondaryEntry,
            'studyUid': studyUid,
            'windowUid': uuid.v4()
          })
        }
        else {
          this.$store.commit('addSecondaryWorklistEntry', secondaryEntry)
          workflow.openSecondaryReport(studyUid)
        }
      }
      else {
        this.$log.warn(`Secondary entry not found for studyUid=${studyUid}`)
      }
    },
  }
};
</script>
<style>
@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-documenteditor/styles/material.css';
.editorDiv {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.timerDiv {
  width: 60px;
  max-width: 60px;
  min-width: 60px;
}
</style>
